<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button v-if="searchStatus" status="my-orange" @click="searchStatus = false">隐藏查询</vxe-button>
        <vxe-button v-if="!searchStatus" status="my-orange" icon="fa fa-search" @click="searchStatus = true">查询</vxe-button>
        <vxe-button v-permission="'NursingHomeNursingHomeAddButton'" status="my-orange" icon="fa fa-plus" @click="handleAdd()">添加</vxe-button>
      </template>
    </vxe-toolbar>
    <div v-if="searchStatus" class="search">
      <vxe-form title-align="right" title-width="120" title-colon :data="listQuery" @submit="handleSearch(true)" @reset="handleSearch(true, true)">
        <vxe-form-item title="名称" span="6">
          <vxe-input v-model="listQuery.param.name" placeholder="请输入名称" clearable />
        </vxe-form-item>
        <vxe-form-item title="状态" span="6">
          <vxe-select v-model="listQuery.param.status" placeholder="请选择状态" clearable>
            <vxe-option
              v-for="(item, index) in statusArray"
              :key="index"
              :label="item"
              :value="index"
            />
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">应用</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
    </div>
    <CustomList ref="CustomList" :columns="tableColumns" :list-query="listQuery" @getList="getList">
      <CustomPic slot="photo" slot-scope="{row}" :src="row.photo || ''" :src-list="[row.photo]" />
      <template slot="operating" slot-scope="{row}">
        <el-button v-permission="'NursingHomeNursingHomeShowButton'" v-if="row.status === -1" size="small" type="text" @click="handleStatus(row, 0)">显示</el-button>
        <el-button v-permission="'NursingHomeNursingHomeHideButton'" v-else size="small" type="text" @click="handleStatus(row, -1)">隐藏</el-button>
        <el-button v-permission="'NursingHomeNursingHomeCheckButton'" size="small" type="text" @click="handleDetail(row.id)">查看</el-button>
        <el-button v-permission="'NursingHomeNursingHomeEditButton'" size="small" type="text" @click="handleEdit(row.id)">编辑</el-button>
      </template>
    </CustomList>
    <Form ref="Form" @submit="handleSearch()" />
  </div>
</template>

<script>
import CustomList from '@/components/CustomList/index'
import { default as CustomTemplate } from '@/components/CustomList/CustomTemplate'
import { mapActions } from 'vuex'
import Form from './components/form'
export default {
  name: 'NursingHome',
  components: { Form, CustomList, ...CustomTemplate },
  data() {
    return {
      searchStatus: false,
      statusArray: { '-1': '隐藏', '0': '待审核', '1': '正常', '2': '拒绝' },
      listQuery: {
        param: {
          hasTenant: true
        }
      },
      tableColumns: [
        {
          prop: 'name',
          title: '名称',
          minWidth: 200
        },
        {
          title: '图片',
          slot: 'photo'
        },
        {
          prop: 'address',
          title: '详细地址',
          minWidth: 200
        },
        {
          title: '负责人信息',
          formatter: (row, column) => {
            let text = row.contactPerson || ''
            text += row.contactPerson && row.contactPhone ? ' <br/> ' : ''
            text += row.contactPhone || ''
            return text
          },
          minWidth: 150
        },
        {
          title: '价格',
          formatter: (row, column) => {
            const priceStart = row.priceStart > 0 ? Number(row.priceStart) : 0
            const priceEnd = row.priceEnd > 0 ? Number(row.priceEnd) : 0
            if (priceStart === priceEnd) {
              if (priceStart > 0) {
                return `${priceStart} 元`
              } else {
                return '-'
              }
            } else {
              return `${priceStart} ～ ${priceEnd} 元`
            }
          },
          minWidth: 150
        },
        {
          title: '状态',
          formatter: (row, column) => {
            if (row.status in this.statusArray) {
              let text = this.statusArray[row.status]
              if (row.status === 2 && row.reason) {
                text += `：${row.reason}`
              }
              return text
            } else {
              return ''
            }
          },
          minWidth: 100
        },
        {
          prop: 'account',
          title: '登录账号',
          minWidth: 150
        },
        {
          title: '操作',
          fixed: 'right',
          slot: 'operating',
          width: 150
        }
      ]
    }
  },
  methods: {
    ...mapActions(['nursingHomePublicPageList', 'nursingHomeUpdate']),
    handleSearch(resetCurrentPage = false, resetSearch = false) {
      if (resetSearch) {
        Object.assign(this.listQuery, this.$options.data.call(this).listQuery)
      }
      this.customListGetList(resetCurrentPage)
    },
    customListGetList(resetCurrentPage = false) {
      this.$refs.CustomList.getList(resetCurrentPage)
    },
    getList(params, callback) {
      this.nursingHomePublicPageList(params).then(response => {
        callback(response)
      }).catch(() => {})
    },
    handleAdd() {
      this.$refs.Form.handleAdd()
    },
    handleDetail(id) {
      this.$refs.Form.handleDetail(id)
    },
    handleEdit(id) {
      this.$refs.Form.handleEdit(id)
    },
    handleStatus(row, status) {
      this.$confirm(`确认${status === 0 ? '显示' : '隐藏'}吗？`, '', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let tenantArr = row.tenants.split('|')
        tenantArr = tenantArr.map((item) => {
          let tenant = item.split(',')
          tenant = tenant.slice(2)
          tenant = tenant.map((item) => {
            return Number(item)
          })
          return tenant
        })
        const params = {
          id: row.id,
          name: row.name,
          photoId: row.photoId,
          introduction: row.introduction,
          tenant: tenantArr,
          address: row.address,
          latitude: row.latitude,
          longitude: row.longitude,
          contactPerson: row.contactPerson,
          contactPhone: row.contactPhone,
          priceStart: row.priceStart,
          priceEnd: row.priceEnd,
          status
        }
        params.tenant = params.tenant.map((item) => {
          return `1,101,${item.join(',')}`
        })
        this.nursingHomeUpdate(params).then(res => {
          if (res.code !== 200) {
            this.$message.error(res.msg)
            return
          }
          this.$message.success('操作成功')
          this.handleSearch()
        }).catch(() => {})
      }).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
  .wrapper{
    overflow: auto;
  }
</style>
